<template>
	<pui-datatable
		v-if="isSuperAdmin"
		:modelName="model"
		:modelColumnDefs="columnDefs"
		:externalFilter="externalFilter"
		:showDeleteBtn="true"
		:actions="actions"
		:masterDetailColumnVisibles="masterDetailColumnVisibles"
	></pui-datatable>
	<pui-datatable
		v-else
		:modelName="model"
		:modelColumnDefs="columnDefs"
		:externalFilter="externalFilter"
		:showDeleteBtn="true"
		:actions="actions"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import DataSourceInstanceActions from './PmDataSourceInstanceActions';

//import constants from '@/utils/constants';
import { isSuperAdmin, search } from '../../api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmdatasourceinstancegrid',
	data() {
		return {
			model: 'pmdatasourceinstance',
			actions: DataSourceInstanceActions.actions,
			masterDetailColumnVisibles: {
				name: true,
				code: true,
				pmareaname: true,
				pmdatasourcetypename: true,
				longitude: true,
				latitude: true,
				deliveryperiod: true,
				unitname: true,
				datasourcetypeicon: true,
				sla: true,
				disabled: true,
				organizationname: true
			},
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				sla: {
					render: (data) => {
						if (data) {
							return (data += '%');
						}
						return data;
					}
				},
				ismobile: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				datasourcetypeicon: {
					createdCell: (td, cellData) => {
						td.style.padding = '6px 0px 0px 20px';
						td.style.width = '3rem';
						return (td.innerHTML = '<img src="' + this.iconPath(cellData) + '" style="max-width:1.5rem;max-height:1.5rem"></img>');
					}
				}
			},
			isSuperAdmin: false
		};
	},
	computed: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		console.log('mounted device grid');

		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
	},
	beforeDestroy() {
		console.log('destroyed device grid');
		//this.updateDeviceStore();
	},
	updated() {
		console.log('updated device grid');
	},
	methods: {
		iconPath(route) {
			var appProperties = this.$store.getters.getApplication;
			return appProperties.urlbase + route;
		},
		async updateDatasourcetypeStore() {
			let rulesDtsType = [];
			if (!this.isSuperAdmin) {
				rulesDtsType.push({ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid });
			}
			const filterDatasourcetype = {
				groups: [],
				groupOp: 'and',
				rules: rulesDtsType
			};

			const body = {
				model: 'pmdatasourcetype',
				filter: filterDatasourcetype,
				rows: 100
			};

			//console.log(body);
			//console.log(filterDatasourcetype);

			const { data } = await search(body);
			console.log('datasourcetype data', data.data);
			this.$store.commit('setDatasourcetype', data.data);
		},
		async updateDeviceStore() {
			var filterDevice = null;
			if (!this.isSuperAdmin) {
				filterDevice = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 }
					]
				};
			}

			let body = {
				model: 'vlupdevicespatialmodel',
				filter: filterDevice,
				rows: 1000
			};
			let { data } = await search(body);
			this.$store.commit('setDevicesData', data.data);
			this.$store.commit('setHasDevices', Object.keys(data.data).length > 0);

			return data;

			////console.warn('hasDevices AppContainerHeader: ' + data.data.length);
			////console.warn(data.data);
		}
	}
};
</script>

<style lang="postcss" scoped></style>
